.mainDivAllOrders {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.orderContainer {
    border: 3px solid #ccc;
    padding: 25px;
    margin-bottom: 20px;
    width: 530px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}
