.discogsPictureDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.discogsPicture {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.discogsLink {
    color: blue;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.8rem;
}

.ag-theme-material .ag-row {
    border-bottom: 1px solid #b6b6b6;
}

.ag-theme-material .ag-cell {
    border-right: 1px solid #ddd;
}

/* Hide the filter dropdown */
.ag-theme-material .ag-filter-select {
    display: none !important;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: popup-animation 0.3s ease-out;
}

.popup-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

@keyframes popup-animation {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .ag-theme-material {
        width: 100% !important;
    }

    .ag-header-cell, .ag-cell {
        font-size: 0.7rem;
    }
    
    .discogsPicture {
        padding: 5px;
        width: 80%;
        height: auto;
        font-size: 0.9rem;
    }
}