.orderSummaryMainDiv {
    text-align: left;
    margin: 30px auto;
    padding: 20px;
    max-width: 600px;
    border: 3px solid #ccc;
    padding: 25px;
    margin-bottom: 20px;
    width: 530px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.orderSummaryMainDiv p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.5;
}

.orderSummaryMainDiv p span {
    color: #28a745;
    cursor: pointer;
    font-weight: bold;
}
