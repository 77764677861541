.feedbackContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    margin-top: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 90%;
}

.feedbackHeader {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.feedbackSubheader {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.feedbackForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.feedbackTextfield {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 1rem;
}

button:hover {
    transform: scale(1.1);
}

