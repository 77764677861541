.container {
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
}

.title {
    margin-bottom: 16px;
}

.textField {
    width: 100%;
    margin-bottom: 16px;
}

.buttonDiv {
    margin-top: 10px;
}
