.mainDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 50px;
    gap: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    padding-bottom: 40px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.formLabel {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    text-align: center;
}

.frontPagePDiv {
    text-align: center;
    width: 100%;
}

.separator {
    width: 90%;
    border: 1px solid #d4d4d4;
    margin: 20px auto;
}

.infoBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    background-color: #e2e2e2;
    border-radius: 8px;
    padding: 15px;
    width: 90%;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.infoBox p {
    margin: 10px 0;
    text-align: center;
}

.infoBox span {
    cursor: pointer;
    transition: color 0.3s;
}

.infoBox:hover {
    background-color: #d6d6d6;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}

.infoBox span:hover {
    color: #1bd8f5;
}

@media (max-width: 768px) {
    .mainDiv {
        width: 80%;
    }

    .infoBox {
        width: 70%;
        padding: 2px;
        font-size: 0.9rem;
    }
}

@media (min-width: 1024px) {
    .mainDiv {
        width: 500px;
    }

    .infoBox {
        width: 60%;
    }
}
