/* Existing styles */
.chat-container {
    text-align: center;
    padding: 10px;
}

.chat-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    height: 550px;
    overflow-y: auto;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 20px auto;
}

.message {
    margin-bottom: 10px;
    text-align: left;
}

.message-right {
    text-align: right;
}

.message-content {
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    max-width: 500px;
    word-wrap: break-word;
}

.message-sent {
    background-color: #DCF8C6;
}

.message-received {
    background-color: #E0E0E0;
}

.message-time {
    font-size: 10px;
    color: #aaa;
    margin-top: 5px;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-field {
    width: calc(100% - 50px);
    margin-bottom: 10px;
}

.send-button {
    margin-left: 10px;
}

.select-user {
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.message-system {
    text-align: center;
    margin: 20px 0;
}

.message-system-header {
    font-size: 16px;
    font-weight: bold;
    color: #1976D2;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.message-system-content {
    background-color: #E3F2FD;
    border: 2px solid #90CAF9;
    color: #000000;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
}

.message-time-system {
    color: #555;
    font-style: italic;
    margin-top: 5px;
}

.chatTextField {
    width: 570px;
}

.active-chat {
    border: 2px solid rgb(49, 49, 255);
    border-radius: 4px;
    font-weight: bold;
}

.user-list {
    width: 20%;
    max-height: 750px;
    border-right: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .chat-box {
        height: 350px;
        max-width: 300px;
        padding: 8px;
    }

    .chatTitle {
        font-size: 1.2rem;
    }

    .chatParagraph {
        font-size: 0.8rem;
    }

    .text-field {
        width: 100%;
    }

    .send-button {
        margin-left: 0;
        margin-top: 10px;
    }

    .select-user {
        width: 100%;
        max-width: none;
    }

    .chatTextField {
        width: 270px;
    }

    .user-list {
        font-size: 9px;
        padding-left: 0px;
        min-width: 20%;
    }
}

