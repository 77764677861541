.frontPageMainDiv {
    text-align: center;
}

.contentSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
}

.section {
    margin: 10px 0;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
}

.homePageTitles {
    margin-bottom: 10px;
    color: #002470;
    border-bottom: 2px solid #002470;
    padding-bottom: 5px;
    font-size: 1.5em;
}

.homePageParagraph {
    line-height: 1.5;
}

b {
    color: #002470;
}

footer {
    background-color: #313131;
    color: white;
    text-align: center;
    padding: 20px;
}

.frontPageClickable {
    cursor: pointer;
    margin-bottom: 10px;
}

.frontPageClickable:hover {
    text-decoration: underline;
}

.homePageAnchorTag {
    color: #1bd8f5;
    font-size: 20px;
    text-decoration: none;
    background-image: linear-gradient(to right, #002470, #ff7e5f, #ff0000);
    -webkit-background-clip: text;
    color: transparent;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.homePageAnchorTag:hover {
    text-shadow: 0 0 5px rgba(255, 126, 95, 0.5), 0 0 10px rgba(255, 126, 95, 0.5);
}

.homePageAnchorTag:visited {
    color: inherit;
}

.ulFrontPage {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 500px;
    overflow-y: auto;
}

.ulFrontPage li {
    background-color: #e9f0f7;
    border: 2px solid #007bff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 17px;
    color: #000;
    position: relative;
}

.ulFrontPage li:hover {
    background-color: #d0e4f3;
    cursor: pointer;
}

.notification-time {
    font-size: 12px;
    color: #333333;
    display: block;
    margin-top: 5px;
}

.genreNSizeFormatter {
    margin: 20px 0;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #999999;
}

.formatItem {
    display: block;
    margin-bottom: 7px;
    font-size: 0.95rem;
}

.formatLabel {
    color: #2155ff;
}

@media (min-width: 768px) {
    .contentSection {
        flex-direction: row;
        justify-content: space-around;
    }

    .section {
        max-width: 600px;
    }

    .vertical-line {
        width: 3px;
        background: linear-gradient(to bottom, rgb(244, 252, 255), #002470, rgb(244, 252, 255));
        margin: 10px;
    }
}

@media (max-width: 767px) {
    .vertical-line {
        display: none;
    }

    .homePageTitles {
        font-size: 1.2em;
    }

    .frontPageTitle {
        font-size: 1.5em;
    }

    .homePageParagraph {
        font-size: 0.9em;
        line-height: 1.7;
    }
}

@media (min-width: 2000px) {
    footer {
        padding-bottom: 25px;
        padding-top: 30px;
    }
}