.search-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .search-textfield {
    background-color: white;
    border-radius: 10px;
    flex: 1; 
    min-width: 150px;
  }
  
  .search-span {
    color: white;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  @media (max-width: 768px) {
    .search-container {
      gap: 5px;
    }
  
    .search-textfield {
      width: 180px;
      font-size: 0.8rem;
      padding: 2px;
      box-sizing: border-box;
    }
  
    .search-span {
      font-size: 0.8rem;
    }
    
  }

  @media (max-width: 330px) {
    .search-textfield {
      width: 120px;
      font-size: 0.8rem;
      padding: 2px;
      box-sizing: border-box;
    }
}
  