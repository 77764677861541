.banner-container {
    position: relative;
    width: 90%;
    overflow: hidden;
    margin: auto;
    margin-top: 10px;
  }
  
  .scroll-container {
    display: flex;
    gap: 15px;
  }
  
  .image {
    min-width: 150px;
    height: 150px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .image:hover {
    transform: scale(1.15);
    z-index: 1;
  }
  
  .banner-container::before,
  .banner-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    z-index: 2;
  }
  
  .banner-container::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  
  .banner-container::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.preview-container {
  position: relative;
}

.preview-image {
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.close-preview {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}

.close-preview:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Example: semi-transparent black on hover */
}

  