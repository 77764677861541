.mainDivOwnOrders {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.status-step {
    display: inline;
    padding: 5px;
    margin: 0 5px;
    font-weight: normal;
    color: #b3b3b3;
    font-size: 1rem;
    position: relative;
}

.status-step.active {
    font-weight: bold;
    color: #2cd832;
    font-size: 1.1rem;
    cursor: pointer;
}

.tooltip {
    visibility: hidden;
    background-color: #2cd832;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 3px 5px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    font-size: 0.8rem;
    opacity: 0;
}

.status-step.active:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 768px) {
    .mainDivOwnOrders {
        padding: 10px;
    }
    
    .orderContainer {
        width: 95%;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .status-step {
        display: block;
        margin: 5px 0;
        font-size: 0.9rem;
    }
    
    .tooltip {
        width: auto;
        font-size: 0.7rem;
    }
    
    ul {
        padding: 0;
    }
    
    li {
        margin-bottom: 15px;
        margin-left: 20px;
    }

    h2 {
        font-size: 1.2rem;
    }
    
    h3 {
        font-size: 1rem;
    }
    
    h4 {
        font-size: 1rem;
    }
}
