.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-badge {
  background-color: #f52b5d;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 16px; /* Set equal width and height for a perfect circle */
  height: 16px; /* Set equal width and height for a perfect circle */
  position: absolute;
  top: -2px;
  right: 5px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
}

.notification-badge-shoppingcart {
  width: 16px;
  height: 16px;
  font-size: 12px;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}