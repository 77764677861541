/* Existing Navbar Styling */
.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: #313131;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-left {
  margin-right: auto;
}

.nav-link {
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: transform 0.5s ease;
  position: relative;
  display: inline-block;
  margin: 0 10px;
  line-height: 1.5;
  vertical-align: middle;
  transform-origin: center;
}

.nav-link:hover {
  transform: scale(1.2);
}

.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.nav-link-shoppingcart {
  margin-right: 75px;
}

.hamburgerMenuButton {
  display: none;
}

.searchBarDiv.first {
  display: flex;
}

.searchBarDiv.second {
  display: none;
}

.nav-link-shoppingcart.first {
  display: flex;
}

.nav-link-shoppingcart.second {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    display: none; 
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #313131;
    padding-top: 10px;
    padding-bottom: 10px;    
  }

  .navbar.isOpen {
    display: block;
    position: fixed;
    width: 100%;
    margin-top: 50px;
  }

  .hamburgerMenuButton {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #313131;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1001;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }

  .nav-left,
  .nav-right {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-left {
    margin-right: 0;
  }

  .nav-link {
    margin: 5px 0;
  }

  .nav-link-shoppingcart {
    margin-right: 0;
  }

  .searchUnderline {
    width: 80%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: rgb(56, 119, 255);
  }

  .nav-linkMobile {
    margin: auto;
  }

  .nav-linkMobileCreateUser {
    display: none;
  }

  .searchBarDiv {
    width: 200px;
  }

  .searchBarDiv.first {
    display: none;
  }

  .searchBarDiv.second {
    display: flex;
    position: fixed;
    top: 10px;
    left: 40px;
    z-index: 1002;
    box-sizing: border-box;
  }

  .nav-link-shoppingcart.first {
    display: none;
  }

  .nav-link-shoppingcart.second {
    display: flex;
    position: fixed;
    top: 6px;
    right: 15px;
    background-color: #313131;
    padding: 10px;
    z-index: 1002;
    box-sizing: border-box;
  }
}
